<template>
  <v-container class="fill-height background-pattern pa-0" fluid>
    <!--  Navbar  -->
    <u-base-nav />

    <v-row align-md="stretch" class="mx-8" justify="center">
      <!--  Carousel Window  -->
      <v-col cols="12" md="8" order="2" order-md="0">
        <login-carousel :items="carouselItems" class="fill-height" />
      </v-col>

      <!--  Login Form  -->
      <v-col cols="12" md="4">
        <v-card
          class="py-10 px-12 fill-height d-flex flex-column justify-center"
          elevation="0"
          rounded="lg"
        >
          <v-container fluid>
            <h1 class="login-title font-weight-bold">Login</h1>
            <u-text-field
              v-model="username"
              class="mt-4"
              label="Username"
              large
              placeholder="Username"
            />
            <u-text-field
              v-model="password"
              label="Password"
              large
              placeholder="Password"
              type="password"
            />
            <p
              class="text-decoration-underline text-right mb-12 cursor-pointer"
              @click="showForgotPasswordModal = true"
            >
              <small> Forgot Password </small>
            </p>

            <v-alert
              v-if="error"
              class="text-center"
              color="accent"
              text
              type="error"
            >
              <strong>{{ error_message }}</strong>
            </v-alert>

            <u-button
              block
              class="text-capitalize font-weight-bold"
              color="secondary"
              large
              @click="login"
            >
              Sign In
            </u-button>
            <u-button
              block
              class="text-capitalize my-2 font-weight-bold"
              color="secondary"
              large
              outlined
            >
              Try for free
            </u-button>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <forgot-password-modal v-model="showForgotPasswordModal" />
  </v-container>
</template>

<script>
import { UBaseNav, UButton, UTextField } from "@/components/base";
import { LoginCarousel } from "../components";
import { ForgotPasswordModal } from "@/components/modals";

import { BOTH_TOKENS, setJwt } from "@/utils";
import ApiService from "@/services/ApiService";

export default {
  name: "Login",
  components: {
    ForgotPasswordModal,
    LoginCarousel,
    UBaseNav,
    UTextField,
    UButton,
  },
  data: () => ({
    username: "",
    password: "",

    carouselItems: [
      {
        title: "Traffic analysis in a click",
        description: "Lorem ipsum so dolor amet",
        image: require("../assets/gray-pattern.svg"),
        link: "",
      },
      {
        title: "Traffic analysis in a click",
        description: "Lorem ipsum so dolor amet",
        image: "",
        link: "",
      },
      {
        title: "Traffic analysis in a click",
        description: "Lorem ipsum so dolor amet",
        image: "",
        link: "",
      },
    ],

    error: false,
    error_message: "Invalid Credentials",

    showForgotPasswordModal: false,
  }),
  methods: {
    async login() {
      this.error = false;
      this.error_message = "";

      const params = {
        email: this.username,
        password: this.password,
      };
      try {
        const res = await ApiService.login(params);

        setJwt(BOTH_TOKENS, {
          accessToken: res.data["access_token"],
          refreshToken: res.data["refresh_token"],
        });

        await this.$router.push({ name: "Dashboard" });
      } catch (e) {
        console.log(e);
        this.error = true;
        this.error_message = e.response.data?.message ?? e.response.data;
      }
    },
  },
};
</script>

<style scoped>
.background-pattern {
  background: url("../assets/primary-pattern.svg") no-repeat;
  background-size: cover;
}

.login-title {
  font-size: 3em;
}
</style>
